import { Routes, Route } from 'react-router-dom';
import { Login, Dashboard, NotFoundPage, Settings , Projects , Charges , Revenue } from '../pages';
import { MainLayout } from '../layout';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';



const Routers = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/revenues"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Revenue />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/expenses"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Charges />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Projects />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default Routers;
