import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Import the Tailwind CSS file
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './i18next';
import { I18nextProvider } from 'react-i18next';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18n}>
      <Router>
        <App />
      </Router>,
    </I18nextProvider>
);

