import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser } from '../api/users';
import { useAuth } from './AuthContext';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout , login } = useAuth()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setCurrentUser(user.user);
      } catch (error) {
        setError(error);
        logout();
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, [login ]);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};
