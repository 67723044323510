import React, { useState, useEffect } from 'react'

function Input({ type, label, handleChange, name, defaultV, disabled }) {
  const [value, setValue] = useState(defaultV || '')
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setValue(defaultV || '')
  }, [defaultV])

  const handleInputChange = (e) => {
    setValue(e.target.value)
    if (handleChange) handleChange(e)
  }

  const handleFocus = () => setIsFocused(true)
  const handleBlur = (e) => {
    setIsFocused(false)
    if (handleChange) handleChange(e)
  }

  const isFloating = isFocused || value || type === 'date'

  return (
    <div className="relative w-full">
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder=" "
        className={`
          peer py-2 px-4 block w-full h-12 border bg-neutral-50 border-neutral-200 
          rounded-lg text-sm placeholder:text-transparent 
          focus:border-blue-500 focus:ring-blue-500 
          disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 
          dark:focus:ring-neutral-600
          ${isFloating ? 'pt-6 pb-2' : ''}
          autofill:pt-6 autofill:pb-2
        `}
      />
      <label
        htmlFor={name}
        className={`
          absolute -top-1 start-0 p-4 h-full text-sm truncate pointer-events-none 
          transition ease-in-out duration-100 border border-transparent origin-[0_0] 
          dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
          ${isFloating ? 'scale-90 translate-x-0.5 -translate-y-1.5 text-gray-500 dark:text-gray-500' : ''}
        `}
      >
        {label}
      </label>
    </div>
  )
}

export default Input
