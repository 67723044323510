import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';

function DeleteModal({ setDeleteId, DeleteId, Model, setDone, setLoding, done  ,open , setOpen}) {


    const deleteRecord = async (id) => {
        try {
            setLoding(true)
            const response = await axiosInstance.delete(`/${Model}/delete/${id}`);
            const responseData = response.data
            if (responseData ) {
                setDeleteId(null)
                setDone(!done)
                setOpen(false)
                toast.success(responseData.message)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setDeleteId(null)
            setLoding(false)
            console.error('User deletion failed:', error.response.data);
        }
    }

    return (
        <div>
            <AnimatePresence>
                {DeleteId && open &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="max-w-md py-4 relative mx-auto my-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                            <div className="">
                                <div className="text-center  flex-auto justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 -m-1 flex items-center text-red-500 dark:text-red-600 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 flex items-center text-red-500 dark:text-red-600 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <h2 className="text-xl dark:text-gray-100 font-bold py-3 ">Are you sure ?</h2>
                                    <p className="text-md text-gray-500 dark:text-gray-300 px-8">Do you really want to delete this record ?
                                        This process cannot be undone</p>
                                </div>
                                <div className="mt-4 text-center space-x-4 md:block">
                                    <button onClick={() => { setDeleteId(null);setOpen(false) }} className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-neutral-100">
                                        Cancel
                                    </button>
                                    <button onClick={(e) => { deleteRecord(DeleteId) }} className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-lg hover:shadow-lg hover:bg-red-600">Delete</button>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence></div>
    )
}

export default DeleteModal