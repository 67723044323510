import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Select , Loading} from '../../components';
import { DeleteModal } from '../../components';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { Calendar, DollarSign, FileText, Timer, CheckCircle, AlertCircle } from 'lucide-react'


function Projects() {
  const { t } = useTranslation();


  const [ProjectModal, setProjectModal] = useState(false)
  const [ProjectId, setProjectId] = useState(null)

  const [searchValue, setSearchValue] = useState(null)


  const [formData, setFormData] = useState({ budget: null, status: null, end_date: null, start_date: null, description: null, title: null })
  const [loading, setLoding] = useState(false)
  const [ProjectsList, setProjectsList] = useState([])
  const [done, setDone] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(ProjectsList.length / itemsPerPage);
  const displayedItems = ProjectsList.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );


  const statuses = [
    { name: t('ongoing'), id: 'ongoing' },
    { name: t('completed'), id: 'completed' },
    { name: t('cancelled'), id: 'cancelled' }
  ]
  useEffect(() => {
    getProject()

  }, [ProjectId, done])

  const getProject = async () => {
    try {
      setLoding(true)
      const response = await axiosInstance.get('/projects/search');
      const responseData = response.data

      if (responseData) {
        setProjectsList(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const InitialiseProject = () => {
    setFormData({ etat: null, name: null })
  }

  const handleToggleModal = (id) => {
    if (id) {
      setProjectId(id)
      getProjectToUpdate(id)

    } else {
      setProjectModal(!ProjectModal)
      InitialiseProject()

    }
  }

  const getProjectToUpdate = async (id) => {
    try {
      setLoding(true)
      const response = await axiosInstance.get(`/projects/get/${id}`);
      const responseData = response.data
      if (responseData) {
        setFormData(responseData.data)
        setProjectModal(!ProjectModal)

      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  }
  const find = async (e) => {
    try {
      e.preventDefault()
      setLoding(true)
      const response = await axiosInstance.post(`/projects/find`, { searchValue });
      const responseData = response.data

      if (responseData) {
        setProjectsList(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const save = async (e) => {
    e.preventDefault();
    try {
      setLoding(true)
      let response = null
      if (ProjectId) {
        response = await axiosInstance.put(`/projects/update/${ProjectId}`, formData);
      } else {
        response = await axiosInstance.post('/projects/create', formData);
      }
      const responseData = response.data
      if (responseData.data) {
        if (ProjectId) {
          toast.success(responseData.message);
          setProjectId(null)
          setProjectModal(false)
          InitialiseProject()
          setDone(!done)
        } else {
          toast.success(responseData.message);
          handleToggleModal()
          setDone(!done)
        }
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false)
    }
  };


  const reset = () => {
    setSearchValue('')
    getProject()
    setFormData({
      title: '',
      description: '',
      start_date: '',
      end_date: '',
      status: '',
      budget: ''
    })
  }
  const getStatusBadgeClass = (status) => {
    const baseClasses = "px-3 py-1 rounded-full text-xs font-medium"
    switch (status) {
      case 'ongoing':
        return `${baseClasses} bg-blue-400/10 text-blue-400`
      case 'completed':
        return `${baseClasses} bg-green-400/10 text-green-400`
      case 'cancelled':
        return `${baseClasses} bg-red-400/10 text-red-400`
      default:
        return `${baseClasses} bg-gray-400/10 text-gray-400`
    }
  }

  return (
    <div className="flex flex-col w-[100%] justify-start p-5  h-[100vh]">
      {loading &&
                <Loading />
            }
      <div className="sm:flex sm:items-center mb-6 sm:justify-between">
        <div className='flex flex-col justify-center items-start'>
          <div className="flex items-center gap-x-3">
            <ol className="flex items-center whitespace-nowrap mb-2">


              <li className="inline-flex items-center text-xl font-semibold text-gray-800 dark:text-white" aria-current="page">
                {t("Projects")}
              </li>
              <li className='-mt-1'>
                <span className="px-3 ml-3 py-1  text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400 lowercase">{ProjectsList.length} {t("Project")}</span>

              </li>
            </ol>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{t("Here you can manage all of your projects.")}</p>
        </div>
        <div className="flex items-center mt-4 gap-x-3">

          <button onClick={() => { handleToggleModal() }} className="flex items-center justify-center w-fit px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{t("Add Project")}</span>
          </button>

        </div>
      </div>
      <div className="relative md:w-1/2 mb-5 w-full">
        <label htmlFor="hs-table-search" className="sr-only">{t("Search")}</label>
        <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} name="hs-table-search" id="hs-table-search" className="py-2 cursor-pointer px-3 ps-9 block w-full border border-neutral-200 bg-neutral-50 h-12 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder={t("Search for items")} />
        <a onClick={(e) => reset(e)} className="absolute top-4 left-2 z-50 flex items-center ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2 z-45  text-gray-800 dark:text-neutral-100">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>


        </a>
        <button onClick={(e) => find(e)} disabled={!searchValue} className="block h-8 absolute top-2 right-2 flex items-center  justify-center   px-3 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100 lowercase">{t("Search")}</button>

      </div>
      <div className="flex flex-col max-w-full ">
        <div className="-m-1.5 ">
          <div className=" w-full  inline-block align-middle">
            <div className="border rounded-lg divide-y overflow-hidden max-w-full  bg-white dark:bg-neutral-800 divide-neutral-200 dark:border-neutral-700 dark:divide-neutral-700">

              <div className="overflow-x-auto max-w-full">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                  <thead className=" bg-neutral-50 dark:bg-neutral-900 h-12">
                    <tr>

                      <th className="px-6 py-4 text-start text-xs font-semibold text-neutral-800 dark:text-neutral-200 uppercase tracking-wider">{t("Title")}</th>
                      <th className="px-6 py-4 text-start text-xs font-semibold text-neutral-800 dark:text-neutral-200 uppercase tracking-wider">{t("Description")}</th>
                      <th className="px-6 py-4 text-start text-xs font-semibold text-neutral-800 dark:text-neutral-200 uppercase tracking-wider">{t("Dates")}</th>
                      <th className="px-6 py-4 text-start text-xs font-semibold text-neutral-800 dark:text-neutral-200 uppercase tracking-wider">{t("Status")}</th>
                      <th className="px-6 py-4 text-start text-xs font-semibold text-neutral-800 dark:text-neutral-200 uppercase tracking-wider">{t("Budget")}</th>
                      <th className="px-6 py-4 text-start text-xs font-semibold text-neutral-800 dark:text-neutral-200 uppercase tracking-wider">{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                    {displayedItems.length > 0 ?
                      displayedItems.map(item => {
                        return (
                          <tr key={item}>

                            <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-neutral-600 dark:text-neutral-400">{item.title}</td>
                            <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-neutral-600 dark:text-neutral-400 max-w-xs truncate">
                              {item.description}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                              <div className="flex items-start flex-col gap-1">
                                <span className="flex items-center text-neutral-600 dark:text-neutral-400 gap-2">
                                  <Timer className="w-4 h-4 text-blue-400" />
                                  {item.start_date}
                                </span>
                                <span className="flex items-center text-neutral-600 dark:text-neutral-400 gap-2">
                                  <Calendar className="w-4 h-4 text-purple-400" />
                                  {item.end_date}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 text-start py-4 whitespace-nowrap">
                              <span className={getStatusBadgeClass(item.status)}>
                                {item.status}
                              </span>
                            </td>
                            <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-neutral-600 dark:text-neutral-400">
                              <span className="flex items-center gap-2">
                                <DollarSign className="w-4 h-4 text-green-400" />
                                {item.budget}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <div className="flex justify-start gap-2">
                                <button
                                  onClick={() => handleToggleModal(item.id)}
                                  className="p-2 bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 rounded-lg transition-colors duration-200"
                                >
                                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => {
                                    setProjectId(item.id)
                                    setDeleteModal(true)
                                  }}
                                  className="p-2 bg-red-500/10 hover:bg-red-500/20 text-red-400 rounded-lg transition-colors duration-200"
                                >
                                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </button>
                              </div>
                            </td>

                          </tr>
                        )
                      })
                      :
                      <tr className="w-full">
                        <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                          {t("There is no data to be displayed !!!!")}
                        </td>
                      </tr>

                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="relative my-6">
            {displayedItems.length > 0 &&

              <ReactPaginate
                pageCount={pageCount}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName=" w-full flex justify-center  items-center "
                pageClassName="border dark:border-neutral-700 flex justify-center items-center ml-2 w-8  h-8 rounded-full"
                activeClassName="bg-green-500 text-white"
                previousClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute left-0 px-6 py-2 rounded-full"
                nextClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute right-0   px-6 py-2 rounded-full"
                disabledClassName="opacity-50 cursor-not-allowed"
                breakClassName="mx-2"
                pageLinkClassName="hover:text-green-500"
                previousLinkClassName="hover:text-green-500"
                nextLinkClassName="hover:text-green-500"
                breakLinkClassName="hover:text-green-500"
              />
            }
          </div>
        </div>
      </div>


      <AnimatePresence>

        {ProjectModal &&
          <div
            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[40%] py-4 relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
              <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                  <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white">{t("Project")}</h2>
                  <a onClick={() => handleToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                </div>
                <div className='grid md:grid-cols-2 w-full place-items-center gap-3 '>

                  <Input type='text' disabled={false} label={t("Title")} name="title" handleChange={handleChange} defaultV={formData.title || null} />

                  <Input type='date' disabled={false} label={t("Start Date")} name="start_date" handleChange={handleChange} defaultV={formData.start_date || null} />

                  <Input type='date' disabled={false} label={t("End Date")} name="end_date" handleChange={handleChange} defaultV={formData.end_date || null} />

                  <Input type='number' disabled={false} label={t("Budjet")} name="budget" handleChange={handleChange} defaultV={formData.budget || null} />


                  <div className="md:col-span-2 grid w-full gap-3">
                    <Select label={t("Status")} disabled={false} options={statuses} name="status" handleChange={handleChange} defaultV={formData.status || null} />

                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder={t("Project Description")}
                      rows={4}
                      className="w-full peer py-2 px-4 block w-full border bg-neutral-50 border-neutral-200 
          rounded-lg text-sm  
          focus:border-blue-500 focus:ring-blue-500 
          disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 
          dark:focus:ring-neutral-600"
                    />
                  </div>


                </div>

                <div className='md:col-span-3 w-full flex items-center justify-end '>
                  <div className="flex items-center mt-6 gap-x-3">
                    <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                      {
                        !loading ?
                          (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span>{t("Save project")}</span>
                            </>
                          )
                          :

                          (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                            <span className="sr-only">Loading...</span>
                          </div>)

                      }

                    </button>
                  </div>
                </div>

              </div>
            </motion.div>
          </div>
        }
      </AnimatePresence >


      <DeleteModal setDeleteId={setProjectId} open={deleteModal} setOpen={setDeleteModal} DeleteId={ProjectId} Model='projects' setDone={setDone} setLoding={setLoding} done={done} />

    </div >


  )
}

export default Projects