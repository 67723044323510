import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LayoutDashboard, FolderKanban, DollarSign, Receipt, Settings, ChevronLeft, ChevronRight } from 'lucide-react'
import avatar from '../../assets/images/avatar.jpg'
import useCurrentUser from "../../utils/hooks/useCurrentUser";

import logo from '../../assets/images/logo.webp'

const menuItems = [
  { name: 'Dashboard', icon: LayoutDashboard, path: '/dashboard' },
  { name: 'Projects', icon: FolderKanban, path: '/projects' },
  { name: 'Revenues', icon: DollarSign, path: '/revenues' },
  { name: 'Expenses', icon: Receipt, path: '/expenses' },
  { name: 'Settings', icon: Settings, path: '/settings' },
]

const Sidebar = ({ open, setOpen }) => {
  const location = useLocation()
  const { currentUser } = useCurrentUser();

  return (
    <>
      <aside 
        className={`fixed z-50 flex flex-col bg-white dark:bg-neutral-800 
          ${open ? 'w-64' : 'md:w-[4.5rem] w-0'} 
          transition-all duration-300 shadow-lg min-h-screen border-neutral-200 dark:border-neutral-700 border-r`}
      >
        <div className={`relative flex w-full ${open ? 'justify-between' : 'justify-center'} items-center h-20 px-4`}>
          <Link to="/dashboard" className="flex items-center space-x-3">
            <img src={logo} alt="Logo" className="h-10 w-10" />
            {open && <span className="text-xl font-semibold dark:text-white">Management</span>}
          </Link>
        </div>

        <nav className="flex-1 overflow-y-auto py-6">
          <ul className="space-y-2 flex flex-col justify-center  px-3">
            {menuItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.path}
                  className={`flex items-center space-x-3 px-3 py-2 rounded-lg transition-colors duration-200
                    ${location.pathname === item.path 
                      ? 'bg-blue-100 text-blue-600 dark:bg-blue-900/20 dark:text-blue-300' 
                      : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-neutral-700'
                    }`}
                >
                  <item.icon size={22} />
                  {open && <span className="font-medium">{item.name}</span>}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="p-4 border-t border-neutral-200 dark:border-neutral-700">
          {open ? (
            <div className="flex items-center space-x-4">
              <img src={avatar} alt="User" className="w-10 h-10 rounded-full" />
              <div className='flex flex-col items-start w-full'>
                <p className="font-medium text-start dark:text-white">{currentUser?.fullName}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{currentUser?.email}</p>
              </div>
            </div>
          ) : (
            <img src={avatar} alt="User" className="w-10 h-10 rounded-full mx-auto" />
          )}
        </div>
      </aside>

      {open && (
        <div 
          onClick={() => setOpen(false)} 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
        />
      )}
    </>
  )
}

export default Sidebar

