import React from 'react'

function Loading() {
    return (
        <div>
            <div className="absolute top-0 inset left-0 bg-black z-50 w-screen h-screen   bg-opacity-10 dark:bg-neutral-800"></div>

            <div className="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-black-600 rounded-full dark:text-gray-100" role="status" aria-label="loading">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default Loading