import React, { useState } from 'react'
import { DarkMode, UserDropDown } from '../../components'



const Navbar = ({ open, setOpen }) => {



  return (
    <nav className={`flex fixed  ${open ? 'md:pl-64' : 'md:pl-[4.5rem]'} z-30 w-full transition-all duration-300 bg-white  h-[4.3rem] dark:bg-neutral-800  justify-between border-b  border-neutral-200 dark:border-neutral-700 `}>
      <div className='flex justify-start items-center px-6 text-gray-800 dark:text-gray-200 gap-6'>
        <a onClick={() => setOpen(!open)} className='cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-align-left"><line x1="21" x2="3" y1="6" y2="6" /><line x1="15" x2="3" y1="12" y2="12" /><line x1="17" x2="3" y1="18" y2="18" /></svg>

        </a>

      </div>

      <div className='flex justify-center items-center gap-2 z-50 px-6'>
        <DarkMode />
        <UserDropDown />

      </div>
    </nav>
  )
}

export default Navbar
