import React, { useEffect, useState } from "react";
import useCurrentUser from "../../utils/hooks/useCurrentUser";
import { Briefcase, DollarSign, BarChartIcon as charts, TrendingUp, BarChart2 } from 'lucide-react';
import { Loading } from "../../components";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios/axiosConfig";
import ReactECharts from 'echarts-for-react';

const colors = {
  blue: "#3b82f6",
  green: "#10b981",
  red: "#ef4444",
  purple: "#8b5cf6",
  yellow: "#f59e0b",
  pink: "#ec4899",
  indigo: "#6366f1",
  teal: "#14b8a6",
  orange: "#f97316",
};

const ChartCard = ({ title, children }) => (
  <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-md overflow-hidden">
    <div className="p-4 border-b border-neutral-200 dark:border-neutral-700">
      <h2 className="text-lg font-semibold text-neutral-800 dark:text-neutral-100">{title}</h2>
    </div>
    <div className="p-4">
      {children}
    </div>
  </div>
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white dark:bg-neutral-800 p-2 border border-neutral-200 dark:border-neutral-700 rounded shadow-md">
        <p className="text-neutral-800 dark:text-neutral-100">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const formatNumber = (num) => {
  return `$${new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num)}`;
};

const DashboardCard = ({ title, value, Icon, color }) => (
  <div className={`relative overflow-hidden rounded-lg p-6 bg-white dark:bg-neutral-800 border-l-4 ${color}`}>
    <div className="relative z-10">
      <h3 className="text-sm font-medium text-neutral-600 dark:text-neutral-300 mb-1">{title}</h3>
      <p className="text-2xl font-bold text-neutral-800 dark:text-neutral-100">{value}</p>
    </div>
    <Icon className="absolute right-4 bottom-4 w-12 h-12 text-neutral-200 dark:text-neutral-700" />
  </div>
);

function Dashboard() {
  const { currentUser } = useCurrentUser();
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({ startDate: "", endDate: "", projectId: "" });
  const [loading, setLoading] = useState(false);
  const [chartsData, setChartsData] = useState(null);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getAnalytics();
    getChartsAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/statistiques/get-cards-analytics", filters);
      const responseData = response.data;

      if (responseData) {
        setData(responseData);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to fetch analytics.");
    } finally {
      setLoading(false);
    }
  };

  const getChartsAnalytics = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/statistiques/get-charts-analytics", filters);
      const responseData = response.data;

      if (responseData) {
        // Transform the data to match the chart requirements
        const transformedData = {
          revenueVsExpensesData: Object.values(responseData.revenueVsExpenses).map(item => ({
            month: `Month ${item.month}`,
            revenue: parseFloat(item.total_revenue),
            expenses: parseFloat(item.expenses)
          })),
          projectStatusData: responseData.projectStatus,
          profitTrendsData: Object.entries(responseData.profitTrends).map(([month, data]) => ({
            month: `Month ${month}`,
            profit: data.profit
          })),
          topProjectsData: responseData.topProjects.map(project => ({
            title: project.title,
            revenue: parseFloat(project.revenues_sum_amount || 0)
          }))
        };
        setChartsData(transformedData);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to fetch analytics.");
    } finally {
      setLoading(false);
    }
  };
  console.log('====================================');
  console.log(chartsData);
  console.log('====================================');

let revenueVsExpensesOption = null;
  let projectStatusOption = null;
  let profitTrendsOption = null;
  let topProjectsOption = null;

  if (chartsData) {
    // Revenue vs Expenses Chart
    revenueVsExpensesOption = {
      title: { text: 'Revenue vs Expenses' },
      tooltip: { trigger: 'axis' },
      legend: { data: ['Revenue', 'Expenses'] },
      xAxis: { type: 'category', data: chartsData.revenueVsExpensesData.map(item => item.month) },
      yAxis: { type: 'value' },
      series: [
        {
          name: 'Revenue',
          type: 'bar',
          data: chartsData.revenueVsExpensesData.map(item => item.revenue)
        },
        {
          name: 'Expenses',
          type: 'bar',
          data: chartsData.revenueVsExpensesData.map(item => item.expenses)
        }
      ]
    };

    // Project Status Chart
    projectStatusOption = {
      title: { text: 'Project Status' },
      tooltip: { trigger: 'axis' },
      legend: { orient: 'vertical', left: 'right' },
      series: [
        {
          name: 'Project Status',
          type: 'pie',
          radius: '50%',
          data: chartsData.projectStatusData.map(item => ({
            value: item.count,
            name: item.status
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    // Profit Trends Chart
    profitTrendsOption = {
      title: { text: 'Profit Trends' },
      tooltip: { trigger: 'axis' },
      xAxis: { type: 'category', data: chartsData.profitTrendsData.map(item => item.month) },
      yAxis: { type: 'value' },
      series: [{
        data: chartsData.profitTrendsData.map(item => item.profit),
        type: 'line'
      }]
    };

    // Top Projects Chart
    topProjectsOption = {
      title: { text: 'Top Projects by Revenue' },
      tooltip: { trigger: 'axis' },
      xAxis: { type: 'category', data: chartsData.topProjectsData.map(project => project.title) },
      yAxis: { type: 'value' },
      series: [{
        name: 'Revenue',
        type: 'bar',
        data: chartsData.topProjectsData.map(project => project.revenue)
      }]
    };
  }
  return (
    <div className="flex flex-col w-[100%] gap-5  justify-start p-5 h-[100vh]">
      {loading && <Loading />}
      <h1 className="text-xl text-start font-bold mb-6 text-neutral-800 dark:text-neutral-100">Dashboard</h1>
      {data ? (
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
          <DashboardCard
            title="Projects"
            value={data.projects.toString()}
            Icon={Briefcase}
            color="border-blue-400"
          />
          <DashboardCard
            title="Revenue"
            value={formatNumber(data.revenue)}
            Icon={DollarSign}
            color="border-green-400"
          />
          <DashboardCard
            title="Expenses"
            value={formatNumber(data.expenses)}
            Icon={charts}
            color="border-red-400"
          />
          <DashboardCard
            title="Profit"
            value={formatNumber(data.profit)}
            Icon={TrendingUp}
            color="border-purple-400"
          />
          <DashboardCard
            title="Growth"
            value={`${data.growthRate}%`}
            Icon={BarChart2}
            color="border-yellow-400"
          />
        </div>
      ) : (
        <p className="text-neutral-500 dark:text-neutral-400">Loading data...</p>
      )}

      {chartsData && (
        <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
          {revenueVsExpensesOption && (
        <div className="bg-white dark:bg-neutral-800 dark:text-neutral-300 rounded-lg shadow-md p-4">
          <ReactECharts option={revenueVsExpensesOption} style={{ height: '400px' }} />
        </div>
      )}
      {projectStatusOption && (
        <div className="bg-white dark:bg-neutral-800 dark:text-neutral-300 rounded-lg shadow-md p-4">
          <ReactECharts option={projectStatusOption} style={{ height: '400px' }} />
        </div>
      )}
      {profitTrendsOption && (
        <div className="bg-white dark:bg-neutral-800 dark:text-neutral-300 rounded-lg shadow-md p-4">
          <ReactECharts option={profitTrendsOption} style={{ height: '400px' }} />
        </div>
      )}
      {topProjectsOption && (
        <div className="bg-white dark:bg-neutral-800 dark:text-neutral-300  rounded-lg shadow-md p-4">
          <ReactECharts option={topProjectsOption} style={{ height: '400px' }} />
        </div>
      )}
        </div>
      )}
    </div>
  );
}

export default Dashboard;

